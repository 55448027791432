import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Layout from './components/Layout';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import ParallaxEffect from './pages/animation/ParallaxEffect';
import Post from './pages/blog-posts/Post';
import Crud from './pages/crud/Crud';
function App() {
  return (
   <>
   <Router>
    <Routes>
      <Route path='/' element={<Layout/>}>
      <Route index element={<Home/>} />
      <Route path='contact' element={<Contact/>} />
      <Route path='parallax' element={<ParallaxEffect/>} />
      <Route path='blogpost' element={<Post/>} />
      <Route path='crud' element={<Crud/>} />
      </Route>
    </Routes>
   </Router>
   </>
  );
}

export default App;
