import { useState } from 'react'
import { app } from '../../firebase/firebase-config';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';





function Crud() {

    const [imageURL, setImageURL] = useState('');


    const db = getFirestore(app);
    const storage = getStorage(app, 'gs://cogina-tn.appspot.com/');


    const imageRef = ref(storage);
    const myimage = ref(imageRef, '10-Best-VIM-Cheat-Sheet-01.jpg');
    console.log(imageRef);

getDownloadURL(ref(storage, '10-Best-VIM-Cheat-Sheet-01.jpg'))
.then((url)=>{setImageURL(url)})
.catch((error)=>{console.log(error)});



    async function getDBData() {
        const data = await getDocs(collection(db, 'users'));
        data.forEach((doc) => { console.log('data is : ', doc.data()) });
    }
    async function getSTData() {

    }
    return (
        <>
            <div>Crud</div>
            <button onClick={getDBData}>get data</button><br />
            <button onClick={getSTData}>get data</button><br />
            <img src={imageURL} alt="myImage" /><br />
        </>
    )
}

export default Crud