import  CustomMap  from "./CustomMap"

function Contact() {
  return (
    <>
    <h1>Map</h1>
      <div style={{margin:'200px'}}>
        <CustomMap/>
      </div>
      <div>
        <h1>Diffrent Section</h1>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus iste quas natus, distinctio est, rem esse rerum tenetur, eligendi nihil quo animi ratione maxime itaque minus ipsum dolorum magni facilis!</p>
      </div>
    </>
  )
}

export default Contact