import React from 'react'

function Footer() {
  return (
    <footer style={{
        backgroundColor: '',
        margin:'200px'}}>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, laboriosam magni nesciunt saepe ea consectetur deleniti molestiae accusamus voluptatem, nisi, quasi eum! Reprehenderit veritatis consequatur voluptatem adipisci illum numquam. Nemo dignissimos perferendis ut quo deserunt quidem! Animi, sequi modi nam ea sint fugit quas dignissimos esse magni doloribus iusto necessitatibus?
</p>
<div className="row">
    <div className="col"><h1>Links</h1>
    <a href="#home">link1</a><br/>
    <a href="#home">link2</a><br/>
    <a href="#home">link3</a><br/>
    </div>
    <div className="col"><h1>Links</h1>
    </div>
    <div className="col"><h1>Links</h1>
    </div>
</div>
    </footer>
  )
}

export default Footer