import React from 'react'

function Nav() {
  return (
<>

<nav className="navbar navbar-expand-lg bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="/">Cog!na.tn</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link" href="parallax">Parallax</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="blogpost">Posts</a>
        </li>
         <li className="nav-item">
          <a className="nav-link" href="crud">CRUD</a>
        </li>
       <li className="nav-item">
          <a className="nav-link" href="contact">Contactez nous!</a>
        </li>
        
      </ul>
        <button className="btn btn-outline-success" type='button'>Search</button>
    </div>
  </div>
</nav>
</>
  )
}

export default Nav