import React from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';

function ParallaxEffect() {
    return (
        <>

           
            <Parallax pages={9}>
                <ParallaxLayer
                    sticky={{ start: 0.5, end: 3.5 }}
                    offset={0}
                    speed={.5}
                    factor={.5}

                >
                    <div className='col-4'
                        style={{
                            backgroundColor: 'yellow',
                        }}>
                        <h2>Rustic Kitchens</h2>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor eum nemo veniam vel, cumque soluta?</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={.2}
                    factor={2}
                >
                    <img
                        style={{
                            width: '100%',
                            height: '100vh',
                            objectFit: 'contain',
                        }}
                        src="./assets/images/rustic-kitchen1.jpg" alt="" />
                </ParallaxLayer>
                <ParallaxLayer
                    offset={2}
                    speed={.2}
                    factor={2}
                >

                    <img
                        style={{
                            width: '100%',
                            height: '100vh',
                            objectFit: 'contain',
                        }} src='./assets/images/rustic-kitchen2.jpg' />
                </ParallaxLayer>
                <ParallaxLayer
                sticky={{start:4, end:5}}
                offset={4}
                speed={.2}
                factor={1} >
                    <div style={{
                        margin: '60px 10px',
                        padding:'10px',
                        border: '1px solid white',
                        backgroundColor: 'white',

                    }}>
                        <h2>Pragraph layer</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum labore ipsum dolorum ipsa. Aperiam inventore animi eaque. Aliquid, nam ullam earum, voluptatibus suscipit accusamus exercitationem quae amet laborum enim minus dolor adipisci perspiciatis temporibus quas praesentium, vel quidem maxime? Maxime, ratione repudiandae rerum hic corrupti suscipit ut ea molestiae quaerat.</p>
                    </div>

                </ParallaxLayer>
                <ParallaxLayer
                    sticky={{ start: 6.5, end: 8.5 }}
                    offset={6}
                    speed={.5}
                    factor={.5}

                >
                    <div className='col-4'
                        style={{
                            backgroundColor: 'lightBlue',
                        }}>
                        <h2>Modern Kitchens</h2>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor eum nemo veniam vel, cumque soluta?</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={5}
                    speed={.2}
                    factor={2}
                >
                    <img
                        style={{
                            width: '100%',
                            height: '100vh',
                            objectFit: 'contain',
                        }} src="./assets/images/modern-kitchen1.jpg" alt="" />
                </ParallaxLayer>
                <ParallaxLayer
                    offset={7}
                    speed={.2}
                    factor={2}
                >

                    <img
                        style={{
                            width: '100%',
                            height: '100vh',
                            objectFit: 'contain',
                        }} src='./assets/images/modern-kitchen2.jpeg' />
                </ParallaxLayer>
            </Parallax>
            <div style={{
                margin: '1000px 0'
            }}>
            </div>
        </>)

}

export default ParallaxEffect