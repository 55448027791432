// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "process.env.REACT_APP_FIREBASE_API_KEY",
  authDomain: "cogina-tn.firebaseapp.com",
  projectId: "cogina-tn",
  storageBucket: "cogina-tn.appspot.com",
  messagingSenderId: "958470849731",
  appId: "1:958470849731:web:3283dad62f962d97d08e76",
  measurementId: "G-VV5QZD86X1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);