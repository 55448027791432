import React, { useState, useEffect, useRef } from 'react'

function Post() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [postImage, setPostImage] = useState('');
    const [newColor, setNewColor] = useState('');
    const [checkedColor, setCheckedColor] = useState(0);
    const [colorChoices, setColorChoices] = useState([{
        page: {
            color: '',
            postArray: [{ element: { title: '', description: '', postImage: '' } }]
        }
    }]);
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);
    const updated = useRef(false);
    const colorAdded = useRef(0);

    useEffect(() => {
        updated.current = true;
        console.log('useEffect updated');
        console.log(checkedColor);
    }, [colorChoices, checkedColor])
    function AddPost() {
        return (
            <>
                {
                    colorChoices[checkedColor].page.postArray.map((array, index) => (
                        <div key={index}
                            style={{
                                border: '1px solid'
                            }}>
                            {index % 2 === 0 ? (
                                <div className="row">
                                    <div className="col">
                                        <img src="" alt="postImage" />
                                        <h3>{array.element.postImage}</h3>
                                    </div>
                                    <div className="col">
                                        <h1>{array.element.title}</h1>
                                        <p>{array.element.description}</p>
                                        <button
                                            onClick={() => {
                                                colorChoices[colorAdded.current === 0 ? colorAdded.current : colorAdded.current - 1].page.postArray.splice(index, 1);
                                                setColorChoices(previousState => [...previousState]);
                                                console.log('array spliced!');
                                                console.log(colorChoices);
                                            }}>D</button>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col">
                                        <h1>{array.element.title}</h1>
                                        <p>{array.element.description}</p>
                                    </div>
                                    <div className="col">
                                        <img src="" alt="postImage" />
                                        <h3>{array.element.postImage}</h3>
                                        <button
                                            onClick={() => {
                                                colorChoices[colorAdded.current === 0 ? colorAdded.current : colorAdded.current - 1].page.postArray.splice(index, 1);
                                                console.log('array spliced!');
                                                setColorChoices(previousState => [...previousState]);
                                            }}>D</button>
                                    </div>

                                </div>
                            )}
                        </div>
                    ))
                }
            </>)
    }

    return (
        <>

            <div className="container"
                style={{
                    backgroundColor: 'white',
                }}>
                <h1>New Post</h1>
                <label htmlFor="exampleColorInput" className="form-label">Color picker</label>
                <input type="color" className="form-control form-control-color" id="exampleColorInput" defaultValue="#563d7c" title="Choose your color" />
                <div className="row">
                    <div className="col">
                        <div className="form-control">
                            <label className='' htmlFor="colorChoice">Couleur</label><br />
                            <input className='form-control' type="text" onChange={e => setNewColor(e.target.value)} /><br />

                            <button className='btn btn-outline-success'
                                onClick={() => {
                                    colorChoices[colorAdded.current].page.color = newColor;
                                    colorChoices.push({
                                        page: {
                                            color: '',
                                            postArray: [{ element: { title: '', description: '', postImage: '' } }]
                                        }
                                    });
                                    colorAdded.current += 1;
                                    console.log(colorAdded);
                                    console.log(colorChoices[colorAdded.current]);
                                    console.log(colorChoices);
                                    setColorChoices(previousState => [...previousState]);
                                    toggleShow();

                                }}>add a new color!</button>


                        </div>
                        <div className="col position-relative">
                            <div className="btn-group position-absolute top-0 end-0">
                                <button type="button" className="btn btn-info dropdown-toggle " data-bs-toggle="dropdown" aria-expanded="false">
                                    Couleurs
                                </button>
                                <ul className="dropdown-menu">
                                    {colorChoices.map((array, index) =>
                                    (
                                        <li key={index} ><a className="dropdown-item" href='#nothing' onClick={
                                            () => {
                                                setCheckedColor(index);
                                                // console.log(index);
                                                // console.log(array.page.color);
                                                // console.log(checkedColor);
                                                // console.log(`array length is ${typeof (colorChoices.length)}`);
                                                // console.log(typeof (index));
                                            }
                                        }>{array.page.color}</a></li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <AddPost />
                <div className="container form-group"
                    style={{
                        backgroundColor: 'white',
                    }}>
                    <div className="row">
                        <div className="col">
                            <input className='form-control' type="file" accept='image/*' name="Image" id="postImage" onChange={e => setPostImage(e.target.value)} />
                        </div>
                        <div className="col">
                            <input className='form-control' type="text" defaultValue={title} onChange={e => setTitle(e.target.value)} /><br />
                            <input className='form-control' type="text" name="description" id="postDescrition" onChange={e => setDescription(e.target.value)} />
                        </div>
                    </div>
                </div>
                <button className='btn btn-outline-success'
                    onClick={() => {
                        console.log(title, ' ', description, ' ', postImage);
                        colorChoices[checkedColor].page.postArray.push({ element: { title: title, description: description, postImage: postImage } });
                        setColorChoices(previousState => [...previousState]);
                        console.log(colorChoices);
                    }}>add Post!</button>


            </div>
        </>

    )
}

export default Post











