

import CustomMap from "../contact/CustomMap";



function Home() {
  return (
    <>
  
      <div className="row g-0" style={{ position: "relative",
    height:'100vh' }}>
        <div className="col-md-8">
          <img style={{
            width: '100%',
            height: '100%',
            objectFit: "cover"
          }} src="assets/images/kitchen-cabinets-white.jpeg" alt="kitchen1" />
        </div>
        <div className="col-md-4"style={{backgroundColor:'#9F0CF5'}}>
          <h1>Best Kitchen Ever!</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis unde quia cupiditate praesentium consequuntur? Dolorum voluptatem voluptate magni facilis magnam!</p>
        </div>
      </div>


      <div className="row g-0">
        <div className="col-4"style={{backgroundColor:'#F5D519'}}>
          <h1>Kitchen with Lamps</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus doloribus eum voluptate voluptatibus! Et sint recusandae molestias fuga ut quaerat?</p> 
        </div>
        <div className="col-8">
          <img style={{
            width: '100%',
            height: '100%',
            objectFit: "cover"
          }} src="assets/images/kitchen-cabinet-sink-hanging-lamps-white.jpg" alt="kitchen3" />
        </div>
      </div>

      <div className="row g-0" style={{ position: "relative" }}>
        <div className="col-8">
          <img style={{
            width: '100%',
            height: '100%',
            objectFit: "cover"
          }} src="assets/images/kitchen-cabinets-blue.jpg" alt="kitchen2" />
        </div>
        <div className="col-4" style={{backgroundColor:'#2581F5'}}>
          <h1> Blue Kitchen</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi expedita rem voluptates consequatur odio, consequuntur modi qui pariatur ut aspernatur.</p>
        </div>
      </div>
    </>
  )
}

export default Home